<template>
  <section class="ContactsTable" :style="{minWidth: '800px'}">
    <Section v-if="error">
      <Headline size="4">Import failed</Headline>
      <span :style="{fontSize: '14px'}" v-html="error"/>
    </Section>
    <Section v-if="success">
      <Headline size="4">Import successful</Headline>
      <span :style="{fontSize: '14px'}" v-html="success"/>
    </Section>

    <div class="ContactsTable__filters">
      <div class="ContactsTable__filters-input-wrapper">
        <Button
          type="secondary"
          @click="createContact"
        >
          Create new contact
        </Button>
      </div>

<!--        Import feature is disabled for now. See DDMCAF-2444-->
<!--      <div class="ContactsTable__filters-btn-wrapper">-->
<!--        <Button type="primary" @click="redirectToTeams()">-->
<!--          List of teams-->
<!--        </Button>-->

<!--        <a :href="templateLink" :style="{textDecoration: 'none'}">-->
<!--          <Button type="primary">-->
<!--            Download template-->
<!--          </Button>-->
<!--        </a>-->

<!--        <Button type="primary" :style="{margin: 0}" @click.native="initImport()">-->
<!--          Upload new contacts-->
<!--        </Button>-->
<!--        <form ref="fileForm" hidden>-->
<!--          <input name="file" type="file" ref="fileContainer" @change="sendXml()">-->
<!--        </form>-->
<!--      </div>-->
    </div>

    <Table
      :collection="contactsList"
      :columns="tableColumns"
      @on-sort="onSort"
    >
      <template v-slot:table-cell-team="slot">
        {{ slot.data ? slot.data.localTitle : '--' }}
      </template>
      <template v-slot:table-cell-phone="slot">
        {{ slot.data ? getMobileValue(slot.data) : '--' }}
      </template>
      <template v-slot:table-cell-requestDate="slot">
        {{ moment(slot.data).format('DD.MM.YYYY') }}
      </template>
      <template v-slot:table-cell-country="slot">
        {{ slot.data ? slot.data.name : '--' }}
      </template>
      <template v-slot:table-cell-email="slot">
        <span class="with-word-break">{{ slot.model ? slot.model.email : '--' }}</span>
      </template>
      <template v-slot:table-cell-edit="slot">
        <Button
          class="ContactsTable__button-edit"
          icon="EDIT"
          form="round"
          type="primary"
          @click.native="updateContact(slot.model)"
        />
      </template>
      <template v-slot:table-cell-delete="slot">
        <Button
          class="ContactsTable__button-delete"
          form="round"
          type="danger"
          icon="BIN_OUTLINE"
          @click.native="deleteContact({ contactId: slot.model.contactId, teamId: slot.model.team?.teamId })"
        />
      </template>
    </Table>
    <TableControls @setLimit="updateLimit($event)" @selectPage="updatePage($event)"
                   :count="count" :limit="limit" :offset="offset" />
    <vue-confirm-dialog class="confirm-dialog"/>
  </section>
</template>

<script>
import upperFirst from 'lodash.upperfirst';
import moment from "moment";

import Table from '@/components/Table/Table.component';
import TableControls from "@/components/Table/TableControls.component";
import InputDropdown from "@/components/Inputs/InputDropdown.component";
import Button from "@/components/Button/Button.component";
import Section from "@/components/Section/Section.component";
import Headline from "@/components/Headline/Headline.component";

import {TableColumnsCollection} from "@/components/Table/classes/TableColumns.collection";
import {TableColumnModel} from "@/components/Table/classes/TableColumn.model";
import {DtoApiImportContactsRequest} from "@/classes/dto/api/contacts/dto.api.import-contacts.request";
import get from "lodash.get";
import flatten from "lodash.flatten";

import RouterNames from "@/router/route.names";

export default {
  name: 'DDMCAF-TicketTable',
  components: {Headline, Section, Table, Button, InputDropdown, TableControls},
  data: () => ({
    matchDay: null,
    match: null,
    success: null,
    error: null,
    tableColumns: []
  }),
  watch: {
    matchDay(newVal) {
      if(newVal) {
        this.getContacts(newVal)
        this.getMatches(newVal)
      }
    },
    match(newVal) {
      if(newVal) {
        this.getContacts(newVal)
      }
    },
    account({organizationId}) {
      this.pageMemo = this.page;
      this.page = 1;
      this.getContacts(organizationId);
    },
  },
  computed: {
    contactsListMeta: {
      get: function () {
        return get(this, '$store.state.contactsList.contactsListMeta')
      },
    },
    account() {
      return get(this, '$store.state.user.account', []);
    },
    pageComputed() {
      return this.request.$loading
          ? this.pageMemo
          : this.page;
    },
    contactsList() { return get(this, '$store.state.contactsList.contactsList') },
    matchDaysList() { return get(this, '$store.state.event.matchDaysList') },
    matchesList() { return get(this, '$store.state.event.matchesList') },
    templateLink() {
      const url = new URL(window.apiUrl);
      url.pathname = 'importTemplates/ticket_contacts_import.xls';
      return url;
    },
    count() {
      return this.contactsListMeta ? this.contactsListMeta.count : 0;
    },
    offset() {
      return this.contactsListMeta ? this.contactsListMeta.offset : 0;
    },
    limit: {
      get: function () { return get(this, '$store.state.contactsList.limit') },
      set: function (newVal) { this.$store.state.contactsList.limit = newVal }
    },
    page: {
      get: function () { return get(this, '$store.state.contactsList.page') },
      set: function (newVal) {this.$store.state.contactsList.page = newVal }
    },
    pages: {
      get: function () { return get(this, '$store.state.contactsList.pages') },
      set: function (newVal) { this.$store.state.contactsList.pages = newVal }
    },
    orderBy: {
      get: function () { return get(this, '$store.state.contactsList.orderBy') },
      set: function (newVal) { this.$store.state.contactsList.orderBy = newVal }
    },
    orderByDirection: {
      get: function () {
        return get(this, '$store.state.contactsList.orderByDirection')
      },
      set: function (newVal) {
        this.$store.state.contactsList.orderByDirection = newVal
      }
    },
  },
  methods: {
    moment,
    upperFirst,
    showDetails() {},
    onSort(event) {
      if(event.sort) {
        if (event.sort === 'requestDate') {
          this.orderBy = 'created_at'
        } else {
          this.orderBy = event.sort
        }

        this.orderByDirection = this.orderByDirection === 'desc' ? 'asc' : 'desc'
        this.getContacts()
      }
    },
    updateLimit(limit) {
      this.limit = limit;
      this.page = 1;
      this.getContacts()
    },
    updatePage(page) {
      this.page = page;
      this.getContacts()
    },
    getContacts(data) { this.$store.dispatch('contactsList/getContacts', {...data, type: 'delivery'}) },
    getTeams(data) { this.$store.dispatch('teamsList/getTeams', data) },
    clearFilters() {
      if(this.matchDay || this.match) {
        this.matchDay = null
        this.match = null
        this.getContacts()
      }
    },
    initImport() {
      const {fileForm, fileContainer} = this.$refs;
      this.setSuccess(null);
      fileForm && fileForm.reset();
      fileContainer && fileContainer.click();
    },
    async sendXml() {
      const {fileForm: form} = this.$refs;
      const payload = new FormData(form);
      const [file] = payload.values();

      if (!file) return this.setError('Microsoft Excel file expected');

      const isExcel = new RegExp('.xls$', 'ig').test(file.name);
      if (!isExcel) return this.setError('Microsoft Excel file expected');

      payload.append('organizationId', this.$store.state.user.account.id);
      payload.append('type', 'delivery');
      const request = await new DtoApiImportContactsRequest().$import(payload);
      if (request.$error) {
        const errorsReceived = get(request, '$response.data.data.errors', {});
        const errors = Object.values(errorsReceived).length
          ? flatten(Object.values(errorsReceived))
          : [];
        const message = get(request, '$response.data.message');
        const messages = flatten(errors);
        const error = messages.length ? messages.join('<br/>') : message;
        return this.setError(error);
      }
      this.setSuccess(request.message);
      this.updatePage(1);
    },
    setError(message) {
      this.success = null;
      this.error = message;
    },
    setSuccess(message) {
      this.success = message;
      this.error = null;
    },
    updateContact(data) {
      this.$emit('contactUpdate', data)
    },
    createContact() {
      this.$emit('contactCreate')
    },
    deleteContact(data) {
      this.$confirm({
        message: `Are you sure you want to delete the contact?`,
        button: {
          no: 'No',
          yes: 'Yes'
        },
        callback: confirm => {
          if (confirm) {
            this.$store.dispatch('contactsList/deleteContact', data).then(() => this.getContacts())
          }
        }
      })
    },
    redirectToTeams() {
      const name = RouterNames.IndexRoutes.DashboardRoutes.Teams;
      this.$router.push({name});
    },
    setTableColumns() {
      this.tableColumns = new TableColumnsCollection([
        new TableColumnModel('team', 'Team', '100px'),
        new TableColumnModel('firstname', 'First Name', '100px', 'firstName'),
        new TableColumnModel('lastname', 'Last Name', '100px','lastName'),
        new TableColumnModel('email', 'Email', '100px'),
        new TableColumnModel('phone', 'Mobile', '80px'),
        new TableColumnModel('address', 'Address', '80px'),
        new TableColumnModel('city', 'City', '80px'),
        new TableColumnModel('zip', 'Zip code', '50px'),
        new TableColumnModel('country', 'Country', '80px'),
        new TableColumnModel('edit', '', '40px'),
        new TableColumnModel('delete', '', '40px'),
      ])
    },
    getSlotTeam(model)
    {
      let result = ''
      if (model && model.teams && model.teams.length > 0) {
        model.teams.forEach((item) => {
          result += item.name
          if (item.teamId !== model.teams[model.teams.length-1].teamId) {
            result += ', '
          }
        })
      }

      return result ? result : '--'
    },
    getMobileValue(data) {
      return data.startsWith("+") ? data : "+" + data
    }
  },
  created() {
    this.setTableColumns(window.outerWidth)

    this.getContacts()
    this.getTeams()
  },
}
</script>

<style lang="scss">
@import "src/styles/main.scss";

.confirm-dialog {
  .vc-btn:last-child {
    color: $danger-color;
  }

  .vc-btn:first-child {
    color: $primary-color;
  }
}

.ContactsTable {
  .button-component__icon {
    font-size: 28px !important;
  }

  &__button-details .button-component__icon {
  }

  &__button-delete .button-component__icon {
    color: $danger-color !important;
    background: #fff !important;
  }

  &__filters {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    > div {
      .button-component:first-child {
        margin-right: 15px;
      }
    }
  }

  &__filters-input-wrapper {
    display: flex;
  }
}
</style>
